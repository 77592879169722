import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpService } from 'weavix-shared/services/http.service';

@Injectable({ providedIn: 'root' })
export class AppService {
    constructor(
        @Inject(DOCUMENT) private document: HTMLDocument,
        private title: Title,
        private rendererFactory: RendererFactory2,
        private deviceService: DeviceDetectorService,
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    private renderer: Renderer2;

    setAppDetails(unauthenticated: (unauthFunction: () => string) => void) {
        const link = this.getFavicon() ?? this.renderer.createElement('link');
        this.appendLinkTag(link, `/assets/radio/favicons/radio-favicon.ico`);
        this.title.setTitle(`weavix™ Radio`);
        HttpService.unauthenticated = () => unauthenticated((): string => {
            if (environment.teamsApp && !this.document.location.href.includes('/teams/login')) {
                const liveLook = this.document.location.href.includes('live');
                const queryParams = this.document.location.href.split('?')[1];
                console.log(`Redirecting to teams login because unauthenticated`);
                return this.document.location.href = `./teams/login${queryParams ? `?${queryParams}` : ''}${liveLook ? queryParams ? '&live=true' : '?live=true' : ''}`;
            } else if (!this.document.location.href.includes('/login') && !this.document.location.href.includes('returnApp')) {
                console.log(`Redirecting to radio login because unauthenticated`);
                return this.document.location.href = `./login/authorize?returnApp=radio`;
            }
        });
    }

    getFavicon() {
        return this.document.querySelector(`link[rel*='icon']`);
    }

    private appendLinkTag(link: any, iconURL: string) {
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = iconURL;
        const head = this.document.getElementsByTagName('head')[0];
        this.renderer.appendChild(head, link);
    }

    isMobile() {
        const data = this.deviceService.getDeviceInfo().os;
        switch (data.toLowerCase()) {
            case 'mac':
                return this.deviceService.isTablet();
            case 'ios':
            case 'android':
                return true;
        }
        return false;
    }
}
