
import { NgModule } from '@angular/core';

/* Components */
import { RightHeaderComponent } from './right-header/right-header.component';
import { SettingsMenuComponent } from './settings-menu/settings-menu.component';

/* Pipes */
import { WeavixSharedModule } from 'weavix-shared/weavix-shared.module';
import { ComponentsModule } from 'components/components.module';
import { CommonModule } from '@angular/common';
import { ConsoleMapModule } from 'map/console-map.module';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';


@NgModule({
    declarations: [
        RightHeaderComponent,
        SettingsMenuComponent,
        NotificationModalComponent,
    ],
    imports: [
        CommonModule,
        WeavixSharedModule,
        ComponentsModule,
        ConsoleMapModule,
    ],
    exports: [
        RightHeaderComponent,
        SettingsMenuComponent,
        ComponentsModule,
        ConsoleMapModule,
        NotificationModalComponent,
    ],
})
export class SharedModule { }
