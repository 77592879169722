<router-outlet></router-outlet>

<ng-container *ngIf="appLoading !== undefined">
    <div class="loading-overlay z600" *ngIf="appLoading === true">
        <app-logo-spinner [size]="100" [slow]="false"></app-logo-spinner>
    </div>
</ng-container>

<app-modal class="loading-modal" [modalInput]="ngswModal" *ngIf="ngswModal">
    <app-loading [loading]="true"></app-loading>
</app-modal>

<app-modal [modalInput]="pwaModal" *ngIf="pwaModal" (modalCloseOutput)="pwaClose($event)">
    <img src="/assets/img/logo-mobile.png" />
</app-modal>
<app-modal [modalInput]="permissionModal" *ngIf="permissionModal" (modalCloseOutput)="permissionClose($event)">
    <div style="justify-content: center;display: flex;flex-flow: column nowrap;align-items: center;">
        <p *ngIf="!teamsApp" class="instructions-error" innerHTML="{{ 'meeting-join.denied-permissions' | translate }}"></p>
        <p *ngIf="teamsApp" class="instructions-error" innerHTML="{{ 'meeting-join.denied-permissions-teams' | translate }}"></p>
        <p class="instructions-details" *ngIf="browserError" innerHTML="{{ browserError | translate }}"></p>
        <div class="teams-callout" *ngIf="teamsApp">
            {{'generics.click-here' | translate}}
        </div>
    </div>
</app-modal>