import { CommonModule, DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

/* Third Party */
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorModule } from 'ngx-device-detector';

/* Modules */
import { SidebarModule } from '../left-navigation-bar/left-navigation-bar.module';
import { SharedModule } from 'shared/shared.module';
import { AppCoreRoutingModule } from './core-routing.module';

/* Components */
import { PageTemplateComponent } from './page-template/page-template.component';

/* Services */
import { FolderService } from 'weavix-shared/services/folder.service';
import { PushService } from 'weavix-shared/services/push.service';
import { OpenPageTemplateComponent } from './components/open-page-template/open-page-template.component';
import { createTranslateLoader } from 'weavix-shared/services/translation.service';
@NgModule({
    declarations: [
        PageTemplateComponent,
        OpenPageTemplateComponent,
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        AppCoreRoutingModule,
        SharedModule,
        SidebarModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        DeviceDetectorModule.forRoot(),
    ],
    providers: [
        /* Use @Injectable({ providedIn: 'root' }) */
        CookieService,
        FolderService,
        PushService,
        { provide: Document, useExisting: DOCUMENT },
    ],
    exports: [RouterModule, SharedModule],
})
export class AppCoreModule {}

