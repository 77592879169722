import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OpenPageTemplateComponent } from './components/open-page-template/open-page-template.component';
import { PageTemplateComponent } from './page-template/page-template.component';

const routes: Routes = [
    {
        path: 'login',
        component: OpenPageTemplateComponent,
        loadChildren: () => import('../login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'a/:accountName',
        children: [
            {
                path: '',
                loadChildren: () => import('./console.module').then(m => m.ConsoleModule),
            },
            {
                path: '**',
                loadChildren: () => import('./console.module').then(m => m.ConsoleModule),
            },
        ],
    },
    {
        path: 'teams',
        children: [
            {
                path: '',
                loadChildren: () => import ('../teams-login/teams-login.module').then(m => m.TeamsLoginModule),
            },
        ],
    },
    {
        path: '**',
        component: PageTemplateComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})

export class AppCoreRoutingModule {}
