import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

/* Modules */
import { SharedModule } from 'shared/shared.module';

/* Components */
import { ConsoleTemplateComponent } from './console-template/console-template.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NewAccountSwitcherComponent } from './new-account-switcher/new-account-switcher.component';

@NgModule({
    declarations: [
        NewAccountSwitcherComponent,
        NavigationComponent,
        ConsoleTemplateComponent,
        NavMenuComponent,
    ],
    providers: [
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
    ],
    exports: [
    ],
})
export class SidebarModule {}
